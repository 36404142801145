<template>
  <div>
   <div class="searchBox">
    <div class="searchLeft">
    </div>
    <div class="searchRight">
      <el-select size="medium" clearable v-model="searchForm.roleId" @change="getRoleMenuList" placeholder="请选择角色">
        <el-option
          v-for="item in roleList"
          :key="item.id"
          :label="item.name+'('+item.description+')'"
          :value="item.id">
        </el-option>
      </el-select>
      <el-button size="medium" type="primary" icon="el-icon-search" @click="allotFunc">分配</el-button>
      <el-button size="medium" type="warning" icon="el-icon-plus" @click="addFunc">增加一级菜单</el-button>
    </div>
   </div>
   <el-table
      style="margin-bottom:50px"
      :data="tableData"
      row-key="id"
      border
      :class="'mytables'"
      ref="multipleTable"
      default-expand-all
      :tree-props="{children: 'menuLists'}"
      @selection-change="handleSelectionChange"
      @select-all="handleSelectAll"
    >
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <!-- <el-table-column type="selection" width="55">
        <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checked" @change="toselect(scope.row)"></el-checkbox>
        </template>
      </el-table-column> -->
      <!-- <el-table-column
        prop="name"
        label="模块名">
        <template slot-scope="scope">
            <span>{{scope.row}}</span>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="name"
        label="模块名">
      </el-table-column>
      <el-table-column
        prop="url"
        label="url">
        <template slot-scope="scope">
          {{scope.row.href || scope.row.url}}
        </template>
      </el-table-column>
      <el-table-column
        width="100"
        prop="id"
        label="ID">
      </el-table-column>
      <el-table-column prop="" label="操作" width="400px">
        <template slot-scope="scope" v-if="scope.row.type !== 'api'">
          <el-button size="mini" type="primary" @click="editFunc(scope.row)">
            <i class="el-icon-edit" style="margin-right:3px;"></i>修改
          </el-button>
          <el-button
            size="mini"
            type="danger"
            @click="deleteType(scope.row)"
          >
            <i class="el-icon-delete" style="margin-right:3px;"></i>删除
          </el-button>
          <el-button size="mini" type="warning" @click="addSubFunc(scope.row)" v-if="scope.row.resourcesList?.length==0||!(scope.row.resourcesList?.length==0&&scope.row.menuLists?.length==0)">
            <i class="el-icon-edit" style="margin-right:3px;"></i>新增子菜单
          </el-button>
          <el-button size="mini" type="info" @click="addApiFunc(scope.row)"  v-if="scope.row.resourcesList?.length>0||(scope.row.resourcesList?.length==0&&scope.row.menuLists?.length==0)">
            <i class="el-icon-edit" style="margin-right:3px;"></i>绑定接口
          </el-button>
        </template>
      </el-table-column>
   </el-table>
   <el-dialog
    :title="!ruleForm.id?'添加菜单':'修改菜单'"
    :visible.sync="dialogVisible"
    width="430px">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="模块名称" prop="name" :rules="rules.blurRule">
            <el-input placeholder="请输入模块名称" v-model="ruleForm.name" style="width:240px"></el-input>
        </el-form-item>
        <el-form-item label="模块路径">
            <el-input placeholder="请输入模块路径" v-model="ruleForm.href" style="width:240px"></el-input>
        </el-form-item>
        <el-form-item label="模块排序" prop="orderNum" :rules="rules.blurRule">
            <el-input placeholder="请输入排序" v-model="ruleForm.orderNum" style="width:240px"></el-input>
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
    </span>
    </el-dialog>
    <el-dialog
    :title="!apiForm.id?'添加接口':'修改接口'"
    :visible.sync="dialogVisibleApi"
    top="3vh"
    width="800px">
    <div class="searchBox">
    <div class="searchLeft">
      <el-input size="medium" v-model="searchForm.name" placeholder="请输入名称"  @keyup.enter.native="getApiTableList"></el-input>
      <el-input size="medium" v-model="searchForm.url" placeholder="请输入地址"  @keyup.enter.native="getApiTableList"></el-input>
      <el-button size="medium" type="primary" icon="el-icon-search" @click="getApiTableList">搜索</el-button>
    </div>
    <div class="searchRight">
      <p>已选：{{apiForm.multipleSelectionApi?.length}} 个</p>
    </div>
   </div>
    
    <el-table
    height="76vh"
      border
      :data="apiTableData"
      ref="apiTable"
      @selection-change="handleSelectionChangeApi"
      row-key="id"
      style="width: 100%">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        width="100"
        prop="id"
        label="id">
      </el-table-column>
      <el-table-column
        prop="name"
        label="名称">
      </el-table-column>
      <el-table-column
        prop="url"
        label="地址">
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleApi = false">取 消</el-button>
        <el-button type="primary" @click="submitApi">确 定</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isRowClick: false,
	  	isAllSelectClick: false,
      totalNum:0,
      searchForm:{},
      tableData: [],
      dialogVisible:false,
      dialogVisibleApi:false,
      ruleForm:{},
      apiForm:{
        multipleSelectionApi:[]
      },
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" }
        ]
      },
      roleList:[],
      multipleSelection:[],
      apiTableData:[],
      roleSelectedData:[],
      isCheckAll:false
    };
  },
  created() {
    this.getTableList()
    this.getRoleList()
    this.getApiTableList()
  },
  methods: {
    handleSelectionChangeApi(val) {
      this.apiForm.multipleSelectionApi = val;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    addApiFunc(row){
      this.dialogVisibleApi=true;
      this.apiForm.id = row.id;
      let multipleSelectionApi =  row.resourcesList.map(x=>{
        if(x.id.indexOf('-')>0){
         return Number(x.id.split('-')[1])
        } else {
          return x.id
        }
      });
      this.$nextTick(()=>{
        this.$refs.apiTable.clearSelection();
        this.apiTableData.forEach(row => {
          if(multipleSelectionApi.includes(row.id)){
            this.$refs.apiTable.toggleRowSelection(row, true);
          }
        });
      })
      
    },
    collectIds() {
      if(this.multipleSelection?.length>0){
        const apiIds = [];
        const menuIds = [];
        this.multipleSelection.forEach(item => {
            if (item.type === 'api') {
                apiIds.push(item.id);
            } else {
                menuIds.push(item.id);
            }
        });
        return { apiIds, menuIds };
      }
    },
    allotFunc(){
        if(!this.searchForm.roleId){
          this.$message.error('请选择需要分配的角色');
          return
        }
        this.$confirm(`确定要分配吗吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const {apiIds, menuIds} = this.collectIds();
          let apiIdsNew = apiIds.map(x=>x.split('-')[1])
          let res = await this.$http.saveRoleMenu({id:this.searchForm.roleId,targetId:menuIds});
          let apires = await this.$http.resourcesSaveRole({id:this.searchForm.roleId,targetId:apiIdsNew});
          if (res&&apires) {
            this.multipleSelection=[];
            this.getTableList();
            this.searchForm.roleId="";
            this.$message.success('操作成功！');
          }
        }).catch((err) => {
               console.log(err) 
        });
    },
    processMenuData(menuData) {
      menuData.forEach(menuItem => {
        menuItem.menuLists.forEach(subMenuItem => {
          subMenuItem.resourcesList.forEach(resource => {
            resource.type = 'api'; 
            resource.id = subMenuItem.id+'-'+resource.id
            subMenuItem.menuLists.push(resource); 
          });
        });
      });
      return menuData;
    },
    async getTableList(){
       let res = await this.$http.menuList();
        if (res) {
        this.tableData=this.processMenuData(res.data)
        }
    },
    processRoleMenuData(data) {
      const result = [];
      data.forEach(item => {
        result.push({ id: item.menuId });
        item.resourceIds.forEach(resourceId => {
          result.push({ id: `${item.menuId}-${resourceId}` });
        });
      });
      return result;
    },
    handleSelectAll(selection) {
      this.isCheckAll = !this.isCheckAll
      if (this.isCheckAll) {
        this.tableData.forEach(menuItem => {
          this.collectSelectedRows(menuItem,'all', selection);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    setSelection(selectedIds) {
      if (selectedIds.length === 0) {
        this.$refs.multipleTable.clearSelection();
        return
      } 
      const selectedRows = [];
      this.tableData.forEach(menuItem => {
        this.collectSelectedRows(menuItem, selectedIds, selectedRows);
      });
      selectedRows.forEach(row => {
        this.$refs.multipleTable.toggleRowSelection(row, true);
      });
    },
    collectSelectedRows(menuItem, selectedIds, selectedRows) {
      if(selectedIds=='all'){
        if (!selectedRows.includes(menuItem)) {
          selectedRows.push(menuItem);
          this.multipleSelection=[...selectedRows]
        }
      } else if (selectedIds.includes(menuItem.id)) {
        selectedRows.push(menuItem);
      }

      if (menuItem.menuLists) {
        menuItem.menuLists.forEach(subMenuItem => {
          this.collectSelectedRows(subMenuItem, selectedIds, selectedRows);
        });
      }
    },
    async getRoleMenuList(){
      this.setSelection([])
      if(!this.searchForm.roleId){
        return
      }
      let res = await this.$http.roleMenuList({roleId:this.searchForm.roleId});
      if (res) {
        this.roleSelectedData = this.processRoleMenuData(res.data)
        let selectedMenu = this.roleSelectedData.map(x=>x.id)
        this.setSelection(selectedMenu)
      }
    },
    async getRoleList(){
      let res = await this.$http.roleList({
        pageNo: 1,
        pageSize: 100
      });
      if (res) {
        this.roleList=res.result.data
      }
    },
    async getApiTableList(){
       let res = await this.$http.resourcesList({
          pageNo: 1,
          pageSize: 300,
          ...this.searchForm
        });
        if (res) {
        this.apiTableData = res.result.data;
      }
    },
    addFunc(){
      this.ruleForm={}
      this.dialogVisible=true
    },
    addSubFunc(row){
      this.ruleForm = {}
      this.ruleForm.parentId=row.id;
      this.dialogVisible=true
    },
    editFunc(row){
      this.ruleForm={...row}
      this.dialogVisible=true;
    },
    deleteType(row){
        this.$confirm(`确定要删除 ${row.name} 模块吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let res = await this.$http.menuDelete([row.id]);
          if (res) {
            this.$message.success('操作成功！');
            this.getTableList()
          }
        }).catch(() => {
                
        });
    },
    async submitForm() {
      this.$refs['ruleForm'].validate(async (valid) => {
          if (valid) {
            let res = null;
            this.ruleForm.parentId = this.ruleForm.parentId || 0
            if (this.ruleForm.id) {
              res = await this.$http.menuUpdate({...this.ruleForm});
            } else {
              res = await this.$http.menuAdd({...this.ruleForm});
            }
            if (res) {
              this.dialogVisible=false
              this.$message.success('操作成功！');
              this.getTableList()
              this.$refs['ruleForm'].resetFields();
            }
          } else {
            console.log('error submit!!');
            return false;
          }
      });
    },
    async submitApi() {
      let apiForm = {...this.apiForm}
      apiForm.targetId = apiForm.multipleSelectionApi.map(x=>x.id)
      let res = await this.$http.saveMenuResource({...apiForm});
      if (res) {
        this.dialogVisibleApi=false
        this.$message.success('操作成功！');
        this.getTableList()
        this.apiForm.multipleSelectionApi = []
      }
    },
  }
};
</script>

<style scoped>


</style>
